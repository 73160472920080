import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageLead from '../components/Page/PageLead'
import PageHero from '../components/Page/PageHero'
import PageStickyBar from '../components/Page/PageStickyBar'
import PageHeading from '../components/Page/PageHeading'
import EventsIndexList from '../components/Events/EventsIndexList'

const EventsPage = ({ location, data }) => {
  const refContainerPrimary = useRef()
  const refContainerSecondary = useRef()

  useEffect(() => {
    const getOffsetTop = element => {
      const top = window.pageYOffset || document.documentElement.scrollTop
      return top + element.getBoundingClientRect().top
    }

    const getOffsetBottom = element => {
      const height = element.getBoundingClientRect().height
      const top = getOffsetTop(element)
      return height + top - window.innerHeight
    }

    const eventScroll = () => {
      if (window.innerWidth < 768) {
        refContainerSecondary.current.parentElement.scrollTop = 0
        return
      }

      const containerPrimaryOffsetTop = getOffsetTop(
        refContainerPrimary.current
      )
      const containerPrimaryOffsetBottom = getOffsetBottom(
        refContainerPrimary.current
      )

      const containerPrimaryHeight = refContainerPrimary.current.getBoundingClientRect()
        .height

      const containerSecondaryHeight = refContainerSecondary.current.getBoundingClientRect()
        .height

      const windowScrollY = window.scrollY
      const windowInnerHeight = window.innerHeight
      const offsetWindowScrollY = windowScrollY - containerPrimaryOffsetTop
      const scrollProgress =
        (offsetWindowScrollY / (containerPrimaryHeight - windowInnerHeight)) *
        100
      if (
        windowScrollY >= containerPrimaryOffsetTop &&
        windowScrollY <= containerPrimaryOffsetBottom
      ) {
        refContainerSecondary.current.parentElement.scrollTop =
          (scrollProgress * (containerSecondaryHeight - windowInnerHeight)) /
          100
      }
    }

    window.addEventListener('scroll', eventScroll)
    return () => {
      window.removeEventListener('scroll', eventScroll)
    }
  }, [])

  const page = data.prismicEventsIndex.data
  const upcoming = data.upcoming.edges
  const previous = data.previous.edges
  return (
    <Layout title={page.title} headerColor="peach">
      <SEO
        title={page.meta_title || page.title}
        description={page.meta_description || ''}
        location={location}
      />
      <PageLead lead={page.lead.html} color="grey" />
      <PageHero image={page.hero} text={page.hero_text} color="green" alt />
      <div className="relative pt-hh">
        <PageStickyBar headings={['Upcoming Events', 'Previous Events']} />
        <div className="relative h-full flex flex-wrap z-10">
          <div className="relative w-full md:w-1/2 bg-grey">
            <div className="w-full" ref={refContainerPrimary}>
              <PageHeading heading={'Upcoming Events'} bg="white" />
              <div className="relative px-ogs pt-10 md:py-10 lg:py-15 z-10">
                <EventsIndexList data={upcoming} heading={'Upcoming Events'} />
              </div>
              <div className="w-full h-hh bg-white md:hidden" />
            </div>
          </div>
          <div className="relative w-full md:w-1/2 md:sticky md:top-0 md:h-screen md:overflow-hidden bg-peach">
            <div className="relative w-full" ref={refContainerSecondary}>
              <PageHeading heading={'Previous Events'} bg="white" />
              <div className="relative px-ogs pt-10 md:py-10 lg:py-15 z-10">
                <EventsIndexList
                  data={previous}
                  heading={'Previous Events'}
                  alt
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

EventsPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default EventsPage

export const pageQuery = graphql`
  query {
    prismicEventsIndex {
      data {
        title
        hero {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hero_text {
          html
        }
        lead {
          html
        }
        meta_description
        meta_title
      }
    }
    upcoming: allPrismicEventsSingle(
      filter: { isFuture: { eq: true } }
      sort: { order: DESC, fields: data___date }
    ) {
      edges {
        node {
          data {
            date
            image_secondary {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2400, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            heading
          }
          uid
        }
      }
    }
    previous: allPrismicEventsSingle(
      filter: { isFuture: { eq: false } }
      sort: { order: DESC, fields: data___date }
    ) {
      edges {
        node {
          data {
            date
            image_secondary {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2400, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            heading
          }
          uid
        }
      }
    }
  }
`
