import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Img from 'gatsby-image'

const ItemDivider = ({ index, alt }) => {
  if (alt) {
    return (
      <div
        className={`w-full pl-ogs last:hidden mb-5 lg:mb-7.5 ${
          index % 2 ? '' : 'lg:hidden'
        }`}
      >
        <hr className="h-px bg-charcoal border-none" />
      </div>
    )
  } else {
    return (
      <div className="w-full last:hidden mb-5 lg:mb-7.5">
        <hr className="h-px bg-charcoal border-none" />
      </div>
    )
  }
}

const EventsIndexItem = ({ index, data, alt }) => {
  return (
    <>
      <div className={`mb-10 lg:mb-14 ${alt ? 'w-full lg:w-1/2 pl-ogs' : ''}`}>
        <AniLink fade to={`events/` + data.uid} className="group">
          {data.data.image_secondary.localFile && (
            <Img
              fluid={data.data.image_secondary.localFile.childImageSharp.fluid}
              alt={data.data.image_secondary.alt}
              className={`${alt ? 'grayscale opacity-50' : ''}`}
            />
          )}
          <div className="flex flex-wrap justify-between items-center leading-tight pt-4">
            <div className={`w-full ${!alt ? 'lg:w-8/12' : ''}`}>
              <h3 className="lg:text-lg opacity-100 transition-opacity duration-200 ease-in-out group-hover:opacity-50 transition-opacity group-hover:transition-opacity">
                {data.data.heading}
              </h3>
            </div>
            {!alt && (
              <div className="hidden lg:block lg:text-right lg:w-4/12 lg:pl-gs text-lg opacity-100 transition-opacity duration-200 ease-in-out group-hover:opacity-50 transition-opacity group-hover:transition-opacity">
                Find out more
              </div>
            )}
          </div>
        </AniLink>
      </div>
      <ItemDivider index={index} alt={alt} />
    </>
  )
}

export default EventsIndexItem
