import React from 'react'
import EventsIndexMessage from './EventsIndexMessage'
import EventsIndexItem from './EventsIndexItem'

const EventsIndexList = ({ data, alt, heading }) => {
  if (data.length > 0) {
    if (alt) {
      return (
        <div className="flex flex-wrap -ml-ogs">
          {data.map((item, index) => {
            return (
              <EventsIndexItem
                key={index}
                index={index}
                data={item.node}
                alt={alt}
              />
            )
          })}
        </div>
      )
    } else {
      return (
        <>
          {data.map((item, index) => {
            return (
              <EventsIndexItem
                key={index}
                index={index}
                data={item.node}
                alt={alt}
              />
            )
          })}
        </>
      )
    }
  } else {
    return <EventsIndexMessage heading={heading} />
  }
}

export default EventsIndexList
