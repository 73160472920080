import React from 'react'

const EventsIndexMessage = ({ heading }) => {
  return (
    <div className="pb-6 md:pb-8 lg:pb-8 mb-5 lg:mb-8">
      <p className="lg:text-lg">Coming soon.</p>
    </div>
  )
}

export default EventsIndexMessage
